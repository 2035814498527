import { FC } from 'react';
import styled from 'styled-components';

export interface OrDividerProps {}

const Wrapper = styled.div`
  color: #292a2b;
  font-size: 18px;
  font-weight: 400;
  line-height: 37px;
  position: relative;
  margin: 14px 0 10px 0;
  text-align: center;

  &:after,
  &:before {
    display: block;
    content: '';
    width: calc(50% - 26px);
    height: 1px;
    background-color: rgba(145, 158, 170, 0.5);
    position: absolute;
    top: calc(50% - 1px);
  }

  &:before {
    left: 0;
  }

  &:after {
    right: 0;
  }
`;

export default (() => <Wrapper>or</Wrapper>) as FC<OrDividerProps>;
