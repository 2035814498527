import noop from '@anm/helpers/noop';
import { SocialProviderNames } from 'global';

export type PostTokenData = {
  token: string;
  provider: SocialProviderNames;
};

export type OnPostToken = (data: PostTokenData) => void;

const POST_TOKEN__ID = 'POST_TOKEN__ID';

export const postToken = (message: PostTokenData) => {
  window?.opener?.postMessage({ message, id: POST_TOKEN__ID }, '*');
};

export const listenPostToken = (onPostToken: OnPostToken = noop) => {
  const removePostTokenListener = () => {
    window?.removeEventListener('message', handlePostToken, false);
  };
  const handlePostToken = ({ data: { id, message } }: MessageEvent) => {
    if (id !== POST_TOKEN__ID) return;

    onPostToken(message);
    removePostTokenListener();
  };
  window?.addEventListener('message', handlePostToken, false);
};
