import { AnimatronProduct, AuthEntry } from '@anm/api/modules/auth';
import composeLodash from 'lodash/fp/compose';

import getWebsiteUrl from '../../helpers/getWebsiteUrl';
import reactRouterRedirect from '../../helpers/redirects/reactRouterRedirect';

export type RedirectProps = {
  entry: AuthEntry;
  product: AnimatronProduct;
  isNewUser: boolean;
};

const bindRedirect = (
  getRedirectUrl: Function,
  { product, entry, isNewUser }: RedirectProps
) => () =>
  composeLodash(
    reactRouterRedirect,
    getRedirectUrl.bind(null, entry, product, isNewUser),
    getWebsiteUrl
  )(product);

export default bindRedirect;
