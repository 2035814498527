import { DOMAttributes, FC } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  font-size: 14px;
  text-align: center;
  color: #2988ee;
  cursor: pointer;
  margin-top: 20px;
  span {
    font-family: Arial;
  }
`;

const BackButton: FC<DOMAttributes<Element>> = ({ children, onClick }) => (
  <Wrapper onClick={onClick}>
    <span>←</span> {children}
  </Wrapper>
);
export default BackButton;
