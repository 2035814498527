import { FC } from 'react';

import { AppleButton } from './Wrapper';
import { useAppleAuthListener, useAppleIDInit, SuccessData } from './hooks';
import { AppleInitSettings } from './types';

export type AppleSignInButtonProps = AppleInitSettings & {
  onSuccess: (data: SuccessData) => void;
};

const AppleSignInButton: FC<AppleSignInButtonProps> = ({
  state,
  scope,
  clientId,
  children,
  onSuccess,
  redirectURI
}) => {
  useAppleAuthListener(onSuccess);
  useAppleIDInit({
    state,
    scope,
    clientId,
    redirectURI
  });

  return (
    <>
      <script
        src="https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js"
        type="text/javascript"
        async
      ></script>

      <AppleButton
        id="appleid-signin"
        data-type="sign in"
        data-color="white"
        data-border="false"
        additionalText={children as string}
      />
    </>
  );
};

export default AppleSignInButton;
