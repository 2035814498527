import { waitForAnalyticFinish } from '@anm/analytic';
import { toObject } from '@anm/helpers/parseParams';

import routes from '../../routes';
import getRouterName from '../router/getRouterName';

export type RouterRedirectQuery = {
  redirectUrl: string;
};

const Router = routes.Router;

const getUrlPath = (link: string) => {
  const url = new URL(link);
  return url.pathname;
};

const getRoute = (url: string) => {
  const isAbsoluteUrl = /^http(s?):\/\//.test(url);
  const path = isAbsoluteUrl ? getUrlPath(url) : url;

  return getRouterName(path);
};

const reactRouterRedirect = async (link: string) => {
  const { redirectUrl }: RouterRedirectQuery = toObject(location.href);
  const url = decodeURIComponent(redirectUrl || link);

  const route = getRoute(url);

  if (route) {
    const { name, params } = route;

    await Router.pushRoute(name, params);
    window.scrollTo(0, 0);
  } else {
    await waitForAnalyticFinish();
    location.href = url;
  }
};

export default reactRouterRedirect;
