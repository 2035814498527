import Button, { ButtonVariants } from '@anm/components/buttons/Button';
import isServer from '@anm/helpers/is/isServer';
import toCamelCase from '@anm/helpers/string/toCamelCase';
import React, { FC } from 'react';

import withAppMeta, { WithAppMetaProps } from '../../../HOCs/withAppMeta';
import OrDivider from '../../OrDivider';
import SocialAuthInColumn from '../SocialAuthInColumn';

import SocialEmailButtonsBlockWrapper from './Wrapper';

export type SocialEmailButtonsBlockProps = WithAppMetaProps & {
  children: string;
  renderQuestionBlock: () => JSX.Element;
  buttonVariant?: ButtonVariants;
  onEmailButtonClick?: () => void;
};

const { Provider, Consumer } = React.createContext<
  Partial<SocialEmailButtonsBlockProps>
>({});
export const SocialEmailButtonsBlockProvider = Provider;

const SocialEmailButtonsBlock: FC<SocialEmailButtonsBlockProps> = ({
  appMeta: { product },
  children: buttonText,
  onEmailButtonClick,
  renderQuestionBlock
}) => {
  const dataAttr = toCamelCase(`${buttonText} with Email`);

  return (
    <Consumer>
      {({ buttonVariant }) => (
        <SocialEmailButtonsBlockWrapper>
          <SocialAuthInColumn product={product}>
            {buttonText} with
          </SocialAuthInColumn>
          <OrDivider />
          <Button
            size="large"
            variant={buttonVariant || 'light-blue-lowercase'}
            onClick={onEmailButtonClick}
            data-button={dataAttr}
          >
            {buttonText} with Email
          </Button>
          {!isServer() && renderQuestionBlock()}
        </SocialEmailButtonsBlockWrapper>
      )}
    </Consumer>
  );
};

export default withAppMeta(SocialEmailButtonsBlock);
