import AppleSignInButton from '@anm/auth/components/networks/AppleSignInButton';
import { SuccessData } from '@anm/auth/components/networks/AppleSignInButton/hooks';
import { useCallback, FC } from 'react';
import { useDispatch } from 'react-redux';

import vtConfig from '../../../config';
import useProductCheck from '../../../hooks/useProductCheck';
import { authActions } from '../../../store/modules/auth';

export type AppleSignInItemProps = {};

const { socialNetworks } = vtConfig();

const AppleSignInItem: FC<AppleSignInItemProps> = ({ children }) => {
  const dispatch = useDispatch();
  const handleAppleSuccess = useCallback(
    ({ authorization: { code } }: SuccessData) =>
      dispatch(
        authActions.postOAuthToken({
          token: code,
          provider: 'APPLE' as 'apple'
        })
      ),
    []
  );

  return (
    <>
      {socialNetworks.apple.apiKey && (
        <AppleSignInButton
          clientId={socialNetworks.apple.apiKey}
          onSuccess={handleAppleSuccess}
          redirectURI={socialNetworks.apple.authUrl}
        >
          {(children as string[]).join(' ')}
        </AppleSignInButton>
      )}
    </>
  );
};

const AppleSignInItemOnlyWave: FC<AppleSignInItemProps> = props => {
  const { isStudio } = useProductCheck();

  return isStudio ? null : <AppleSignInItem {...props} />;
};
export default AppleSignInItemOnlyWave;
