import styled from 'styled-components';

export const AuthQuestionBlock = styled.span`
  font-size: 14px;
  text-align: center;
  color: #919eaa;
  display: inline-block;
  margin-top: 20px;
  * {
    color: #2988ee;
    display: inline;
    font-size: unset;
    line-height: unset;
    vertical-align: unset;
  }
`;

export default AuthQuestionBlock;
