import getFromCdn from '@anm/helpers/getFromCdn';
import { SocialProviderNames } from 'global';
import styled, { css } from 'styled-components';

export interface IconSocialNetworkProps {
  variant: SocialProviderNames;
}

const variants: { [key in SocialProviderNames]: ReturnType<typeof css> } = {
  apple: css``,
  google: css`
    background-image: url(${getFromCdn(
      'images/icons/signup-icons/google.svg'
    )});
  `,
  twitter: css`
    background-image: url(${getFromCdn(
      'images/icons/signup-icons/twitter.svg'
    )});
  `,
  facebook: css`
    background-image: url(${getFromCdn(
      'images/icons/signup-icons/facebook.svg'
    )});
  `
};

export default styled.div<IconSocialNetworkProps>`
  width: 50px;
  height: 50px;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  position: absolute;
  top: 0;
  left: 0;
  ${({ variant }) => variants[variant]};
`;
