import styled from 'styled-components';

const SocialAuthInColumnWrapper = styled.div`
  > * {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export default SocialAuthInColumnWrapper;
