import waitFor from '@anm/helpers/waitFor';
import { useCallback, useEffect } from 'react';

import { AppleInitSettings } from './types';

export type SuccessData = {
  authorization: {
    state: string;
    code: string;
    id_token: string;
  };
  user: {
    email: string;
    name: {
      firstName: string;
      lastName: string;
    };
  };
};

export const useAppleAuthListener = (onSuccess: (data: SuccessData) => void) => {
  const handleSuccess = useCallback((data: CustomEvent<SuccessData>) => {
    onSuccess(data.detail);
  }, []);

  useEffect(() => {
    document.addEventListener('AppleIDSignInOnSuccess', handleSuccess);

    return () => {
      document.removeEventListener('AppleIDSignInOnSuccess', handleSuccess);
    };
  }, []);
};

export const useAppleIDInit = ({ state, scope, clientId, redirectURI }: AppleInitSettings) => {
  useEffect(() => {
    (async () => {
      await waitFor(() => !!window.AppleID, 70, 'wait for apple id init');

      window.AppleID?.auth?.init({
        state,
        scope,
        clientId,
        redirectURI,
        usePopup: true
      });
    })();
  }, []);
};
