import { media } from '@anm/styles/helpers';
import { useCallback, FC } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { openSocialLink } from '../../../helpers/socialAuth';
import { authActions } from '../../../store/modules/auth';

export const SocialNetworkItemMobileStyles = media.phone`
  justify-content: center;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  color: #292a2b;
  border-radius: 5px;
  padding-left: 60px;
  padding-right: 20px;
  position: relative;
  cursor: pointer;
  height: 50px;
  background-color: #fff;
  box-shadow: 0 2px 10px 0 rgba(1, 83, 130, 0.1);

  span {
    text-transform: capitalize;
  }

  ${SocialNetworkItemMobileStyles};

  &:hover {
    background-color: #e9f8ff;
  }
`;

type SocialNetworkItemProps = { url: string };

const SocialNetworkItem: FC<SocialNetworkItemProps> = ({ url, children }) => {
  const dispatch = useDispatch();
  const onClick = useCallback(
    () =>
      openSocialLink(url, data => {
        dispatch(authActions.postOAuthToken(data));
      }),
    ['url']
  );

  return <Wrapper {...{ onClick }}>{children}</Wrapper>;
};

export default SocialNetworkItem;
