import getFromCdn from '@anm/helpers/getFromCdn';
import { media } from '@anm/styles/helpers';
import styled from 'styled-components';

export const mobileStyles = media.phone`
  justify-content: center;
`;

type AppleButtonProps = { additionalText: string };
export const AppleButton = styled.div<AppleButtonProps>`
  background: #fff;
  box-shadow: 0px 2px 10px rgba(1, 83, 130, 0.1);
  border-radius: 5px;
  height: 50px;

  div > div:first-child {
    display: flex;
    text-align: left;
    padding-left: 60px !important;
    padding-right: 20px !important;

    &:hover {
    background-color: #e9f8ff;
    cursor: pointer;
  }

    &:after {
      content: "${({ additionalText }) => `${additionalText} Apple`}";
      display: inline-block;
      line-height: 50px;
      height: 50px;

      font-size: 16px;
      font-weight: bold;
      font-family: 'Inter';
    }
    &:before {
      content: '';
      display: inline-block;
      position: absolute;
      left: 0;
      right: 0;
      width: 50px;
      height: 50px;

      background-position: center;
      background-repeat: no-repeat;
      background-image: url(${getFromCdn('images/icons/apple/black-logo.svg')});
    }

    ${mobileStyles}
  }
  svg {
    display: none;
  }
`;
