import { compose, withProps } from 'recompose';

import bindRedirect, { RedirectProps } from '../helpers/redirects/bindRedirect';
import getLoginRedirectUrl from '../helpers/redirects/getLoginRedirectUrl';
import getSignUpRedirectUrl from '../helpers/redirects/getSignUpRedirectUrl';

import withAppMeta, { WithAppMetaProps } from './withAppMeta';
import withAuth, { WithAuth } from './withAuth';
import withUser, { WithUserProps } from './withUser';

type HOCs = WithUserProps & WithAuth & WithAppMetaProps;

export type RedirectsMeta = {
  redirectsMeta: RedirectProps;
};

export type WithRedirectsProps = {
  loginRedirect: () => void;
  signUpRedirect: () => void;
};

const createRedirectsMetaProp = ({
  appMeta,
  user: { profile },
  auth: { isNew }
}: HOCs): RedirectsMeta => ({
  redirectsMeta: {
    ...(profile ? profile.meta : appMeta),
    product: appMeta.product,
    isNewUser: isNew
  }
});

const createRedirectsProps = ({ redirectsMeta }: RedirectsMeta) => ({
  loginRedirect: bindRedirect(getLoginRedirectUrl, redirectsMeta),
  signUpRedirect: bindRedirect(getSignUpRedirectUrl, redirectsMeta)
});

export default compose<HOCs, WithRedirectsProps>(
  withUser,
  withAuth,
  withAppMeta,
  withProps<RedirectsMeta, HOCs>(createRedirectsMetaProp),
  withProps<WithRedirectsProps, RedirectsMeta>(createRedirectsProps)
);
