import routes from '../../routes';

import { Routes } from './types';

type Settings = {
  cutSlash?: boolean;
};

const cutFirstSlash = (url: string) => url.replace(/^\//, '');

const getUrlByRouterName = (currentName: string, { cutSlash }: Settings = {}) => {
  const route = ((routes as any) as Routes).routes.find(({ name }) => currentName === name);
  const pattern = route?.pattern;
  const url = pattern && (cutSlash ? cutFirstSlash(pattern) : pattern);

  return url;
};

export default getUrlByRouterName;
