import styled from 'styled-components';

const SocialEmailButtonsBlockWrapper = styled.div`
  text-align: center;
  > * {
    width: 100%;
  }
`;

export default SocialEmailButtonsBlockWrapper;
