import { useSelector } from 'react-redux';

import { appMetaSelectors } from '../store/modules/appMeta';

const useProductCheck = () => {
  const product = useSelector(appMetaSelectors.selectAppMetaProduct);
  const isWave = product === 'WAVE';
  const isStudio = product === 'STUDIO';

  return { isWave, isStudio };
};

export default useProductCheck;
