import { AnimatronProduct, AuthEntry } from '@anm/api/modules/auth';
import { Include } from 'helpers';

import getUrlByRouterName from '../router/getUrlByRouterName';

type CalendarTemplateEntry = Include<AuthEntry, 'CALENDAR' | 'TEMPLATE'>;
type UrlProps = {
  product: AnimatronProduct;
  baseUrl: string;
  isNewUser: boolean;
};

const myVideos = getUrlByRouterName('my-projects', { cutSlash: true });

const getUrlByEntry = (entry: CalendarTemplateEntry, baseUrl: string) =>
  ({
    CALENDAR: `${baseUrl}${myVideos}`,
    TEMPLATE: `${baseUrl}video-templates`
  }[entry]);

const getUrlByProduct = ({ product, baseUrl }: UrlProps) =>
  ({
    WAVE: `${baseUrl}${myVideos}`,
    STUDIO: `${baseUrl}home`
  }[product]);

export default (entry: AuthEntry, product: AnimatronProduct, isNewUser: boolean, baseUrl: string) =>
  ['LANDING', 'WAVE_BUTTON', 'APPSUMO'].includes(entry)
    ? getUrlByProduct({ product, baseUrl, isNewUser })
    : getUrlByEntry(entry as CalendarTemplateEntry, baseUrl);
