import Button from '@anm/components/buttons/Button';
import { LinkContext } from '@anm/contexts/LinkContext';
import { useContext, FC } from 'react';
import styled from 'styled-components';

import { TermsUseContext } from './TermsUseContext';

export { TermsUseProvider } from './TermsUseContext';

type TermsUseProps = {
  entity?: string;
  className?: string;
};

const TermsMessage = styled.div`
  font-size: 14px;
  line-height: 24px;
  color: #919cae;
  * {
    line-height: unset;
    font-size: unset;
    vertical-align: unset;
  }
`;

const ButtonLink: FC = ({ children }) => (
  <Button variant="blue-link" size="link-medium">
    {children}
  </Button>
);

const TermsUse: FC<TermsUseProps> = ({ className, entity = 'accessing the service' }) => {
  const { Link } = useContext(LinkContext);
  const { termsLink, privacyLink } = useContext(TermsUseContext);

  return (
    <TermsMessage className={className}>
      By {entity} you agree to our{' '}
      <Link route={termsLink.route}>
        <ButtonLink>{termsLink.title}</ButtonLink>
      </Link>{' '}
      and{' '}
      <Link route={privacyLink.route}>
        <ButtonLink>{privacyLink.title}</ButtonLink>
      </Link>
    </TermsMessage>
  );
};

export default TermsUse;
