import { AnimatronProduct } from '@anm/api/modules/auth';
import FormHelperText from '@anm/components/form/FormHelperText';
import { FC } from 'react';
import { useSelector } from 'react-redux';

import { makeSocialProviders } from '../../../helpers/socialAuth';
import { authSelectors } from '../../../store/modules/auth';

import AppleSignInItem from './AppleSignInItem';
import SocialNetworkIcon from './SocialNetworkIcon';
import SocialNetworkItem from './SocialNetworkItem';
import SocialAuthInColumnWrapper from './Wrapper';

export interface SocialAuthInColumnProps {
  product: AnimatronProduct;
}

const renderSocialNetworkList = (additionalText: string, product: AnimatronProduct) =>
  makeSocialProviders(product).map(({ url, name }) => (
    <SocialNetworkItem key={name} url={url}>
      <SocialNetworkIcon variant={name} />
      {additionalText}{' '}
      <span>
        &nbsp;
        {name}
      </span>
    </SocialNetworkItem>
  ));

const SocialAuthInColumn: FC<SocialAuthInColumnProps> = ({ children, product }) => {
  const error = useSelector(authSelectors.selectAuthError);
  return (
    <SocialAuthInColumnWrapper>
      {renderSocialNetworkList(children as string, product)}
      <AppleSignInItem>{children}</AppleSignInItem>
      {error && (
        <FormHelperText variant="static">{error?.message || error || 'Oops, something went wrong!'}</FormHelperText>
      )}
    </SocialAuthInColumnWrapper>
  );
};

export default SocialAuthInColumn;
