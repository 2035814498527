import { createContext } from 'react';

type LinkProps = {
  title: string;
  route: string;
};
type TermsUseProps = { termsLink: LinkProps; privacyLink: LinkProps };

export const TermsUseContext = createContext({} as TermsUseProps);

export const TermsUseProvider = TermsUseContext.Provider;
