import { AnimatronProduct } from '@anm/api/modules/auth';
import { toUrlParams } from '@anm/helpers/parseParams';
import { SocialProviderNames } from 'global';

import vtConfig from '../../config';

import { listenPostToken, OnPostToken } from './tokenMessage';

type SocialProvider = {
  name: SocialProviderNames;
  url: string;
};

type SocialAuthParams = {
  scope?: string;
  display?: string;
  client_id?: string;
  redirect_uri: string;
  response_type?: string;
};

const productWebsiteUrlsMap: { [key in AnimatronProduct]: string } = {
  WAVE: vtConfig().urls.wave,
  STUDIO: vtConfig().urls.studio.replace('/studio', '')
};

export const makeCallback = (product: AnimatronProduct, provider: string) =>
  `${productWebsiteUrlsMap[product]}callback/${provider}`;

const makeSocialAuthUrl = (url: string, params: SocialAuthParams) => {
  const defaultParams: Partial<SocialAuthParams> = {
    response_type: 'token'
  };

  const paramsWithDefaults = { ...params, ...defaultParams };
  const urlParams = toUrlParams(paramsWithDefaults);

  return `${url}?${urlParams}`;
};

export const makeSocialProviders = (product: AnimatronProduct): SocialProvider[] => {
  const { socialNetworks } = vtConfig();
  const { google, facebook } = socialNetworks;

  const makeCallbackBind = makeCallback.bind(null, product);

  return [
    {
      name: 'facebook',
      url: makeSocialAuthUrl(facebook.authUrl!, {
        scope: 'email',
        display: 'popup',
        client_id: facebook.apiKey!,
        redirect_uri: makeCallbackBind('facebook')
      })
    },
    {
      name: 'google',
      url: makeSocialAuthUrl(google.authUrl!, {
        scope: 'profile email',
        client_id: google.apiKey!,
        redirect_uri: makeCallbackBind('google')
      })
    }
  ];
};

export const openSocialLink = (link: string, onPostToken?: OnPostToken) => {
  listenPostToken(onPostToken);

  window.open(link, 'Auth', 'width=640, height=480,resizable,scrollbars,status');
};
